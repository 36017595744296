export class TransformedTableDataModel<T> {
  constructor(items: T[], totalItemsCount: number, pageNumber?: number, pageSize?: number, totalPagesCount?: number) {
    this.items = items;
    this.totalItemsCount = totalItemsCount;
    this.pageNumber = pageNumber ? pageNumber : 0;
    this.pageSize = pageSize ? pageSize : 0;
    this.totalPagesCount = totalPagesCount ? totalPagesCount : 0;

  }

  items: T[];
  totalItemsCount: number;
  pageNumber: number;
  pageSize: number;
  totalPagesCount: number;
}
