import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable, of} from 'rxjs';
import {ConfirmModalComponent} from '../components/confirm-modal/confirm-modal.component';
import {catchError, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModal: NgbModal) {
  }

  private $modalOptions: NgbModalOptions = {
    centered: true,
    windowClass: 'm-modal',
    scrollable: true
  };

  confirm(text: string, svg?: string, confirmStyle?: string, modalConfirmButton?: string, modalCancelButton?: string):
    Observable<boolean | undefined> {
    const confirmModal = this.ngbModal.open(ConfirmModalComponent, this.$modalOptions);
    confirmModal.componentInstance.modalText = text;
    confirmModal.componentInstance.confirmStyle = confirmStyle;
    confirmModal.componentInstance.modalConfirmButton = modalConfirmButton;
    confirmModal.componentInstance.modalCancelButton = modalCancelButton;
    confirmModal.componentInstance.svg = svg;
    return from(confirmModal.result).pipe(
      take(1),
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
}
