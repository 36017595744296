import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {ApiService} from '../../../../../shared/services/api.service';
import {ResponseModel} from '../../../../../shared/models/response.model';
import {CommentResultInterface} from '../models/comment-result.interface';

@Injectable({
  providedIn: 'root'
})

export class CommentsService {
  // @ts-ignore
  commentListSubject$: BehaviorSubject<CommentResultInterface> = new BehaviorSubject<CommentResultInterface>(undefined);
  commentList$: Observable<CommentResultInterface> = this.commentListSubject$.asObservable();

  constructor(private apiService: ApiService) {
  }

  addComment(id: number, payload: string): Observable<ResponseModel<boolean>> {
    const url = `secured/webinar/${id}/comment`;
    const body = JSON.stringify(payload);
    return this.apiService.post(url, body, true);
  }

  addReplyComment(id: number, commentId: number, payload: string): Observable<ResponseModel<boolean>> {
    const url = `secured/webinar/${id}/comment/${commentId}/reply`;
    const body = JSON.stringify(payload);
    return this.apiService.post(url, body, true);
  }

  getCommentList(queryParams?: object): Observable<ResponseModel<CommentResultInterface>> {
    const url = 'secured/comment';
    return this.apiService.get(url, true, queryParams);
  }

  deleteComment(webinarId: number, webinarCommentId: number): Observable<ResponseModel<any>> {
    const url = `secured/webinar/${webinarId}/comment/${webinarCommentId}`;
    return this.apiService.delete(url, true);
  }

  setCommentList(obj: any): void {
    this.commentListSubject$.next(obj);
  }
}
